import { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/redux.hooks";
import { getFiltersDate } from "../store/commonData/commonSlice";
import { getFilterEVEmissionDate } from "../store/ev/evSlice";


import { checkRolePermission, getLocalStorage, isGmiCompanyEnable } from "utils";
import ProtectedRoute, {
  AuthBucketLoginRouteCheck,
  AuthRouteCheck,
  ProtectedRouteBucket,
} from "../auth/ProtectedRoute";
import RoutePermissionCheck from "../auth/PrivateRoute";
import { BucketFileUpload } from "pages/bucket/fileUpload/BucketFileUpload";
import BucketLoginView from "pages/bucket/login/BucketLogin";
import { routeKey } from "constant/routeKey"
const LocalFreightView = lazy(() => import("pages/localFreight/LocalFreightView"));
const BidMatrixView = lazy(() => import("pages/bidsPlanning/BidMatrixView"));
const OutPutScreenView = lazy(() => import("pages/bidsPlanning/OutPutScreenView"));
const BidsPlanningView = lazy(() => import("pages/bidsPlanning/BidsPlanningView"));
const LaneSuggestionView = lazy(() => import("pages/lanePlanning/LanePlanningView"));
const LoginView = lazy(() => import("../pages/login/LoginView"));
const RegionalView = lazy(() => import("../pages/region/RegionalView"));
const RegionOverview = lazy(
  () => import("../pages/regionOverview/RegionOverview")
);
const SustainView = lazy(() => import("../pages/sustainable/SustainView"));
const VendorView = lazy(() => import("../pages/carrier/VendorView"));
const RegionalLevelView = lazy(
  () => import("../pages/regionalLevel/RegionalLevelView")
);


const LaneView = lazy(() => import("../pages/lanes/LaneView"));
const UserManagementView = lazy(
  () => import("../pages/userManagement/UserManagementView")
);
const RoleManagementView = lazy(
  () => import("../pages/roleManagement/RoleManagementView")
);
const CreateRoleView = lazy(
  () => import("../pages/roleManagement/create/CreateRoleView")
);
const EditRoleView = lazy(
  () => import("../pages/roleManagement/update/EditRoleView")
);

const RoleDetailView = lazy(
  () => import("../pages/roleManagement/view/RoleDetailView")
);
const UserDetailView = lazy(
  () => import("../pages/userManagement/detail/UserDetailView")

)
const UserManagementListView = lazy(
  () => import("../pages/userManagement/UserListView")
);
const EditUserView = lazy(
  () => import("../pages/userManagement/editUser/EditUserView")
);
const FacilityView = lazy(() => import("../pages/facility/FacilityView"));
const FacilityOverviewView = lazy(
  () => import("../pages/facilityOverview/FacilityOverviewView")
);
const LaneOverview = lazy(() => import("../pages/lanesOverview/LaneOverview"));
const UserSettingView = lazy(
  () => import("../pages/usersetting/UserSettingView")
);
const VendorOverviewView = lazy(
  () => import("../pages/carrier/carrierOverview/VendorOverviewView")
);
const CarrierComparisionView = lazy(
  () => import("../pages/carrierComprision/CarrierComparisionView")
);
const PorjectDetailView = lazy(
  () => import("../pages/projectDetail/ProjectDetailView")
);
const BenchmarkLaneTableView = lazy(
  () => import("../pages/benchmarkLaneTable/BenchmarkLaneTableView")
);
const BenchmarkCarrierTableView = lazy(
  () => import("../pages/benchmarkCarrierTable/BenchmarkCarrierTableView")
);
const ProjectView = lazy(() => import("../pages/project/ProjectView"));
const Decarb = lazy(() => import("../pages/decarb/DecarbView"));
const DecarbRecommendedView = lazy(
  () => import("../pages/decarbProblemLanes/DecarbProblemLanesView")
);
const BenchmarkRegion = lazy(
  () => import("../pages/benchmark/banchmarkRegion/BanchmarkRegionView")
);
const BenchmarksView = lazy(() => import("pages/benchmark/BenchmarksView"));
const CompanyBenchmarkView = lazy(
  () => import("pages/benchmark/companyBenchmark/CompanyBenchmarkView")
);

const KnowledgeHub = lazy(() => import("pages/knowledgeHub/KnowledgeHubView"));

const ErrorPage = lazy(() => import("pages/error/ErrorView"));
const FileManagementView = lazy(() => import("../pages/fileManagement/FileManagementView"));

const BusinessUnitView = lazy(
  () => import("pages/businessUnit/BusinessUnitView")
)
const BusinessUnitOverviewView = lazy(
  () => import("pages/businessUnitOverview/BusinessUnitOverviewView")
)
const EvView = lazy(
  () => import("pages/evMap/EvView")
);

/**
 * Component that defines all the routes for the website
 */
const GreenInsightRoute = () => {

  // Fetch user token from local storage
  const userdata: any = getLocalStorage("loginDetails");
  const { loginDetails } = useAppSelector((state: any) => state.auth);

  let tokenDetails: string = userdata?.token;

  // Fetch emission filter dates on component mount
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tokenDetails) {
      dispatch(getFiltersDate());
      dispatch(getFilterEVEmissionDate("SCNN"));
    }
  }, [dispatch, tokenDetails]);


  return (
    <Router basename="/">
      <Routes>
        {/* LoginView route */}
        <Route
          path="/"
          element={
            <AuthRouteCheck userDetails={loginDetails}>
              <Suspense
                fallback={
                  <div className="graph-loader  d-flex justify-content-center align-items-center">
                    <div className="spinner-border  spinner-ui d-flex justify-content-center align-items-center">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                }
              >
                <LoginView />
              </Suspense>
            </AuthRouteCheck>
          }
        />
        <Route
          path="/bucket-login"
          element={
            <AuthBucketLoginRouteCheck>
              <BucketLoginView />
            </AuthBucketLoginRouteCheck>
          }
        />
        <Route element={<ProtectedRouteBucket />}>
          <Route path="/bucket-add" element={<BucketFileUpload />} />
        </Route>

        {/* Protected routes for application panel*/}
        <Route element={<ProtectedRoute roles={[]} />} >
          {/* SustainView route not accessible for regional Manager*/}
          {!checkRolePermission(loginDetails?.data, "regionalManager") && (
            <Route path="/sustainable" key={"sustainable"} element={
              <SustainView />
            } />
          )}
          {/* RegionalLevel-Dashboard route */}
          <Route path="/regional-level" element={<RegionalLevelView />} />

          {/* RegionalView route not accessible for regional Manager*/}
          {!checkRolePermission(loginDetails?.data, "regionalManager") && (
            <Route path="/regional" element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
                <RegionalView />
              </RoutePermissionCheck>
            } />
          )}

          {/* Regional-OverviewView route */}
          {!checkRolePermission(loginDetails?.data, "regionalManager") && (
            <Route
              path="/region-overview/:regionId/:years/:quarters"
              element={
                <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
                  <RegionOverview />
                </RoutePermissionCheck>
              }
            />
          )}

          {/* Carrier route */}
          <Route path="/carrier" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
              <VendorView />
            </RoutePermissionCheck>
          } />

          {/* Lanes route */}
          <Route path="/lanes" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
              <LaneView />
            </RoutePermissionCheck>
          } />

          {/* LaneOverview route */}
          <Route path="/lanes-overview/:laneName/:years/:quarters" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
              <LaneOverview />
            </RoutePermissionCheck>
          } />
          {/* {Carrier-comparison route} */}
          <Route
            path="/carrier-comparison"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
                <CarrierComparisionView />
              </RoutePermissionCheck>
            }
          />

          {/* Facility route */}
          {!isGmiCompanyEnable(loginDetails?.data, ["GMI", "PEP"]) && (
            <Route path="/facility" element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
                <FacilityView />
              </RoutePermissionCheck>
            } />
          )}

          {/* Facility overview route */}
          <Route
            path="/facility-overview/:facilityId/:years/:quarters"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
                <FacilityOverviewView />
              </RoutePermissionCheck>
            }
          />
          {/* {Carrier Overview Route} */}

          <Route
            path="/carrier-overview/:id/detail/:laneName?/:projectId?"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
                <VendorOverviewView />
              </RoutePermissionCheck>
            }
          />

          <Route
            path="/carrier-overview/:id/:years?/:quarters?"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Segmentation}>
                <VendorOverviewView />
              </RoutePermissionCheck>
            } />
          {/* User setting route */}
          <Route path="/settings" element={<UserSettingView />} />

          {/* Project route */}
          <Route path="/projects" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Manage}>
              <ProjectView />
            </RoutePermissionCheck>

          } />
          {/* Project Detail Route */}
          <Route path="/project-detail/:id/:laneName" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Manage}>
              <PorjectDetailView />
            </RoutePermissionCheck>
          } />

          <Route path="/decarb" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Recommendations} checkedChild>
              <Decarb />
            </RoutePermissionCheck>
          } />

          <Route
            path="/decarb-problem-lanes/:id"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Recommendations} checkedChild>
                <DecarbRecommendedView />
              </RoutePermissionCheck>
            }
          />

          {/* lane Planning route */}
          <Route path="/lane-planning/:laneName?/:regionId?" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Recommendations} checkedChild>
              <LaneSuggestionView />
            </RoutePermissionCheck>
          } />

          <Route
            path="/benchmarkLaneTable/:band_no/:emission/:quarterId/:type/:yearId/:wtwType"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
                <BenchmarkLaneTableView />
              </RoutePermissionCheck>
            }
          />

          <Route
            path="/benchmarkCarrierTable"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
                <BenchmarkCarrierTableView />
              </RoutePermissionCheck>
            }
          />

          <Route
            path="/benchmarkLaneTable"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
                <BenchmarkLaneTableView />
              </RoutePermissionCheck>
            }
          />

          {/* BenchMarks Routes */}
          <Route
            path="/benchmarks/:type/:id/:yearId/:quarterId/:wtwType"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
                <CompanyBenchmarkView />
              </RoutePermissionCheck>
            }
          />
          <Route path="/benchmarks" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
              <BenchmarksView />
            </RoutePermissionCheck>
          } />
          <Route
            path="/benchmarks/:type/detail/:id/:yearId/:quarterId/:wtwType/:boundType"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
                <BenchmarkRegion />
              </RoutePermissionCheck>
            }
          />
          <Route
            path="/benchmarks/:type/detail/:id/:yearId/:quarterId/:wtwType"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
                <BenchmarkRegion />
              </RoutePermissionCheck>
            }
          />
          <Route
            path="/benchmarks/view-more/detail/:emissionId/:type/:quarterId/:yearId/:wtwType/:boundType/:id"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
                <BenchmarkCarrierTableView />
              </RoutePermissionCheck>
            }
          />
          <Route
            path="/benchmarks/view-more/:emissionId/:type/:quarterId/:yearId/:wtwType/:bandNumber"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
                <BenchmarkCarrierTableView />
              </RoutePermissionCheck>
            }
          />

          <Route
            path="/benchmarks-carrier-table/:type/detail/:id/:yearId/:quarterId/:wtwType"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.Benchmarks}>
                <BenchmarkRegion />
              </RoutePermissionCheck>
            }
          />
          {/* KnowledgeHub screen route */}
          <Route path="/knowledge-hub" element={<KnowledgeHub />} />
          {/* bid planning Route */}
          {!isGmiCompanyEnable(loginDetails?.data, ["GMI"]) && (<Route path="/bid-planning" element={<RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.BidPlanning}>
            <BidsPlanningView />
          </RoutePermissionCheck>} />)}

          {!isGmiCompanyEnable(loginDetails?.data, ["GMI"]) && (<Route path="/bid-matrics/:file_id/:file_name" element={<RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.BidPlanning}>
            <BidMatrixView />
          </RoutePermissionCheck>} />)}

          {!isGmiCompanyEnable(loginDetails?.data, ["GMI"]) && (<Route path="/bid-output/:file_id/:file_name" element={<RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.BidPlanning}>
            <OutPutScreenView />
          </RoutePermissionCheck>} />)}

        </Route>

        {/* routes for admin panel */}
        <Route element={<ProtectedRoute company={["PEP"]} roles={[]} />} >
          <Route path="/business-unit" element={<BusinessUnitView />} />
          <Route path="/business-unit-overview/:businessUnitId/:years/:quarters" element={<BusinessUnitOverviewView />} />
          <Route path="/RNG-freight" element={<LocalFreightView />} />
          <Route path="/ev-network" element={<EvView />} />
        </Route>


        {/* routes for admin panel */}
        <Route element={<ProtectedRoute application={"admin"} roles={[]} />} >
          {/* user management route */}
          <Route path="/user-management-view/:userId/:roleId?" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.UserManagement}>
              <UserDetailView />
            </RoutePermissionCheck>
          } />

          <Route path="/user-management" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.UserManagement}>
              <UserManagementView />
            </RoutePermissionCheck>
          } />

          {/* user management user list route */}
          <Route
            path="/user-management/userlist"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.UserManagement}>
                <UserManagementListView />
              </RoutePermissionCheck>
            }
          />
          {/* user management user list route */}
          <Route
            path="/user-management-edit/:user_id/:roleId?"
            element={
              <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.UserManagement}>
                <EditUserView />
              </RoutePermissionCheck>
            }
          />

          {/* role management */}
          <Route path="/role-management/view/:roleId" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.RoleManagement}>
              <RoleDetailView />
            </RoutePermissionCheck>
          } />
          <Route path="/role-management/create-role" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.RoleManagement}>
              <CreateRoleView />
            </RoutePermissionCheck>
          } />
          <Route path="/role-management/edit-role/:roleId" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.RoleManagement}>

              <EditRoleView />
            </RoutePermissionCheck>} />

          <Route path="/role-management" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.RoleManagement}>
              <RoleManagementView />
            </RoutePermissionCheck>
          } />

          {/* data management route */}
          <Route path="/data-management" element={
            <RoutePermissionCheck permissionDto={loginDetails} routeKey={routeKey.DataManagement}>
              <FileManagementView />
            </RoutePermissionCheck>
          } />

        </Route>
        {/* ErrorPage route */}
        <Route path="/page-not-found" element={
          <Suspense
            fallback={
              <span className="visually-hidden"></span>
            }
          >
            <ErrorPage />
          </Suspense>
        } />

        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <div className="graph-loader  d-flex justify-content-center align-items-center">
                  <div className="spinner-border  spinner-ui d-flex justify-content-center align-items-center">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              }
            >
              <ErrorPage />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
};

export default GreenInsightRoute;
