import axios from "axios";

/**
 * Retrieves vendor table data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */

const decarbDataGet = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("get-recommended-levers", data, userToken);
        return response?.data;
    } catch (error:any) {
        throw error;
    }
};

const decarbProblemLanesDataGet = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("get-region-problem-lanes", data, userToken);
        return response?.data;
    } catch (error:any) {
        throw error;
    }
};

const optimusLanesApi = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("optimus-fuel-lane", data, userToken);
        return response?.data;
    } catch (error:any) {
        throw error;
    }
};
const optimusRouteCordinates = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("optimus-fuel-stop-data", data, userToken);
        return response?.data;
    } catch (error:any) {
        throw error;
    }
};


const decarbService = {
    decarbDataGet,
    decarbProblemLanesDataGet,
    optimusLanesApi,
    optimusRouteCordinates
};

export default decarbService;