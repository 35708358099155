import axios from "axios";


/**
 * Regional Services
 */

// Function to fetch region table data
const businessUnitTableDataGet = async (userData: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(
            "get-business-unit-table-data", userData, userToken
        );
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

// Function to fetch region graph data
const businessUnitGraphPost = async (userData: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(
            "get-business-emission-data", userData, userToken
        );

        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};


const businessUnitGlidePath = async (userData: any, token: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        return (await axios.post("get-business-reduction", userData, token)).data;
    } catch (error: any) {
        throw error;
    }
};

// Function to fetch region overview detail
const getBusinessUnitOverviewDetail = async (userData: any, tokenDetails: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(`get-business-carrier-overview-detail`, userData, tokenDetails);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}

const businessCarrierComparison = async (userData: any, tokenDetails: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(`get-business-carrier-comparison-graph`, userData, tokenDetails);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}

const businessLaneGraphData = async (userData: any, tokenDetails: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(`get-Business-unit-emission`, userData, tokenDetails);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}


const businessRegionGraphData = async (userData: any, tokenDetails: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(`get-business-unit-emission-by-region`, userData, tokenDetails);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}


// Object containing all regional services
const businessUnitService = {
    businessUnitTableDataGet,
    businessUnitGraphPost,
    businessUnitGlidePath,
    getBusinessUnitOverviewDetail,
    businessLaneGraphData,
    businessCarrierComparison,
    businessRegionGraphData
    
};

// Export the regional service object
export default businessUnitService;
