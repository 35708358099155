import axios from "axios";

/**
 * Retrieves vendor table data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const vendorTableDataGet = async (userData: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        return (await axios.post("get-Vendor-table-data", userData, userToken))
            .data;
    } catch (error: any) {
        throw error;
    }
};

/**
 * Retrieves vendor graph data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */

const vendorGraphPost = async (userData: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        return (await axios.post("get-vendor-emission-data", userData, userToken))
            .data;
    } catch (error: any) {
        throw error;
    }
};

/**
 * Retrieves carrier overview data using a GET request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */

const getCarrierOverview = async (userData: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        return (await axios.post('get-carrier-overview', userData, userToken)).data;
    } catch (error: any) {

        throw error;
    }
};

/**
 * Retrieves lane breakdown data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const getLaneBreakdown = async (userData: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        return (await axios.post("get-lane-breakdown", userData, userToken)).data;
    } catch (error: any) {
        throw error;
    }
};

/**
 * Retrieves lane carrier list data using a GET request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const getLaneCarrierList = async (userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        return (await axios.get('get-lane-carrier', userToken)).data;
    } catch (error: any) {
        throw error;
    }
};

/**
 * Retrieves lane carrier comparison data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const getLaneCarrierCompaire = async (userData: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        return (await axios.post("get-vendor-comparison", userData, userToken)).data;
    } catch (error: any) {
        throw error;
    }
};

/**
 * Retrieves lane carrier table data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const laneCarrierTableDataApi = async (userData: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        return (
            await axios.post("get-lane-carrier-table-data", userData, userToken)
        ).data;
    } catch (error: any) {
        throw error;
    }
};

const getRegionCarrierComparisonTable = async (userData: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        return (
            await axios.post("get-carrier-region-comparison-table-data", userData, userToken)
        ).data;
    } catch (error: any) {
        throw error;
    }
};


/**
 * A collection of functions related to vendor-related API calls.
 */
const vendorService = {
    vendorTableDataGet,
    vendorGraphPost,
    getCarrierOverview,
    getLaneBreakdown,
    getLaneCarrierList,
    getLaneCarrierCompaire,
    laneCarrierTableDataApi,
    getRegionCarrierComparisonTable
};

// Export the vendorService object for use in other parts of the application
export default vendorService;
