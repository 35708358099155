import GreenInsightRoute from './routes/greenInsightRoute';

const App = () => {
  return (
    <div data-testid="app-component">
        <GreenInsightRoute />
    </div>

  );
}

export default App;
