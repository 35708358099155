import axios from "axios";
import { getSearchodpairUrl } from "utils";

// Function to fetch lane graph data
const laneGraphData = async (userData: any, tokenDetails: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(`get-lane-emission-V1`, userData, tokenDetails);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}

// Function to fetch region-carrier comparison data
const regionCarrierComparison = async (userData: any, tokenDetails: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(`get-region-carrier-comparison-data`, userData, tokenDetails);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}

// Function to fetch region overview detail
const getRegionOverviewDetail = async (userData: any, tokenDetails: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(`get-region-overview-detail`, userData, tokenDetails);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}

// Function to fetch lane reduction detail graph
const getLaneReductionDetailGraph = async (userData: any, tokenDetails: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(`get-lane-reduction-graph`, userData, tokenDetails);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}


// Function to fetch lane carrier emission data
const getLaneCarrierEmission = async (userData: any, token: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("get-lane-carrier-graph", userData, token);
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
}

// Function to fetch lane overview details emission data
const getLaneOverDetailsEmissionApi = async (userData: any, token: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("get-lane-overview-details", userData, token);
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
}

// Function to fetch shortest lane path 
const getLaneSortestPathApi = async (userData: any, token: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("alternate-k-shortest-path", userData, token);
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
}

// Function to fetch lane scenario detail
const getLaneScenarioDetailApi = async (userData: any, token: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("get-lane-scenario-detail", userData, token);
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
}

// Function to fetch City
const searchCityApi = async (userData: any, token: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post(getSearchodpairUrl(userData?.page), userData, token);
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
}

// Function to fetch City
const getLaneEmissionDataApi = async (userData: any, token: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("get-lane-emission-table-data-V1", userData, token);
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
}


// Function to fetch City
const getCarrierEmissionDataApi = async (userData: any, token: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("get-carrier-emission-table-data", userData, token);
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
}

// Object containing all lane-related services
const laneService = {
    laneGraphData,
    regionCarrierComparison,
    getRegionOverviewDetail,
    getLaneCarrierEmission,
    getLaneReductionDetailGraph,
    getLaneOverDetailsEmissionApi,
    getLaneSortestPathApi,
    getLaneScenarioDetailApi,
    searchCityApi,
    getCarrierEmissionDataApi,
    getLaneEmissionDataApi
};

// Export the lane service object
export default laneService;
