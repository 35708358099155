import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sustainService from "./sustainService";
import { getErrorMessage, getTokenHeader, isCancelRequest } from "../../utils";
import { SustainState } from "./sustainInterface";

// Define the shape of the state

const initialState: SustainState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  graphRegionChart: null,
  regionEmission: null,
  isLoadingGraphRegionEmission: false,
  regionEmissionIsLoading: false,
  configConstants: null,
  configConstantsIsLoading: false,
  isShowPasswordExpire : false
};

// Async Thunks for fetching sustain service data
export const graphRegionEmission = createAsyncThunk(
  "get/region-emission-graph",
  async (userData: any, thunkApi) => {
    try {
      return await sustainService.getGraphRegionEmission(
        userData,
        getTokenHeader()
      );
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

// Define async thunk to fetch emission region details
export const emissionRegionDetails = createAsyncThunk(
  "post/emissionRegion/Details",
  async (userData: any, thunkApi) => {
    try {
      // Fetch emission region details using sustainService and the token header
      return await sustainService.getRegionEmission(userData, getTokenHeader());
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getConfigConstants = createAsyncThunk(
  "post/config/Details",
  async (data:{region_id:string | number}, thunkApi) => {
    try {
      // Fetch emission region details using sustainService and the token header
      return await sustainService.getConfigConstants(data,getTokenHeader());
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const setShowPasswordExpire = createAsyncThunk("passwordResetPopup", async (status: boolean) => {
  return status
})


export const sustainableReducer = createSlice({
  name: "chart-details",
  initialState,
  reducers: {
    resetSustain: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(graphRegionEmission.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isLoadingGraphRegionEmission = true;
      })
      .addCase(graphRegionEmission.fulfilled, (state, action) => {
        state.isLoading = true;
        state.isSuccess = true;
        state.graphRegionChart = action.payload;
        state.isLoadingGraphRegionEmission = false;
      })
      .addCase(graphRegionEmission.rejected, (state, action) => {
        state.isLoading = true;
        state.isError = action.payload;
        state.isSuccess = false;
        state.isLoadingGraphRegionEmission = isCancelRequest(action?.payload);
      })
      .addCase(emissionRegionDetails.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.regionEmissionIsLoading = true;
      })
      .addCase(emissionRegionDetails.fulfilled, (state, action) => {
        state.isLoading = true;
        state.isSuccess = true;
        state.regionEmission = action.payload;
        state.regionEmissionIsLoading = false;
      })
      .addCase(emissionRegionDetails.rejected, (state, action) => {
        state.isLoading = true;
        state.isError = action.payload;
        state.isSuccess = false;
        state.regionEmissionIsLoading = isCancelRequest(action?.payload);
      })

      .addCase(getConfigConstants.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.configConstantsIsLoading = true;
      })
      .addCase(getConfigConstants.fulfilled, (state, action) => {
        state.isLoading = true;
        state.isSuccess = true;
        state.configConstants = action.payload;
        state.configConstantsIsLoading = false;
      })
      .addCase(getConfigConstants.rejected, (state, action) => {
        state.isLoading = true;
        state.isError = action.payload;
        state.isSuccess = false;
        state.configConstantsIsLoading = isCancelRequest(action?.payload);
      })
      .addCase(setShowPasswordExpire.fulfilled, (state: any, action: any) => {
        state.isShowPasswordExpire = action.payload;
    })
  },
});

export const { resetSustain } = sustainableReducer.actions;
export default sustainableReducer.reducer;
