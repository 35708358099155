// Import the axios library for making HTTP requests
import axios from "axios";
const getEvLocations = async (userToken: { headers: { Authorization: string; }; }): Promise<ApiResponse> => {
    try {
        return (await axios.get('get-ev-locations', userToken)).data;
    } catch (error: any) {
        throw error;
    }
};

const getMatrixDataEVApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        // Send a POST request to the specified API endpoint with user data and token headers
        const response = await axios.post("matrix-data-dashboard-ev", payload, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getFilterEVEmissionDateApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        // Send a POST request to the specified API endpoint with user data and token headers
        const response = await axios.get(`get-filter-emission-dates?scac=${payload}`, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};
const getEvNetworkLanesApi = async (data: any, userToken: { headers: { Authorization: string; }; }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("ev-network-lane", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};

const getTruckLaneDataApi = async (data: any, userToken: { headers: { Authorization: string; }; }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("truck-lane-data", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};


const getEVShipmentLanesApi = async (data: any, userToken: { headers: { Authorization: string; }; }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("truck-lane-data", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};



const getEVShipmentsByDateApi = async (data: any, userToken: { headers: { Authorization: string; }; }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("truck-lane-data", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};


// Create an object that contains all the facility-related service functions
const evService = {
    getEvLocations,
    getMatrixDataEVApi,
    getFilterEVEmissionDateApi,
    getEvNetworkLanesApi,
    getTruckLaneDataApi,
    getEVShipmentLanesApi,
    getEVShipmentsByDateApi
};

// Export the facilityService object as the default export
export default evService;
