import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import localFreightService from "./localFreightService";
import { getTokenHeader, getErrorMessage, isCancelRequest } from "../../utils";
import { LocalFreightState } from "./localFreightInterface";

/**
 * Redux Slice for managing bid planning data
 */

// Define the shape of the state

// Initial state
const initialState: LocalFreightState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    isLoadingTruckFuelTypesPieChart: false,
    truckFuelTypesPieChart: null,
    isLoadingEmissionIntensityRNGBarChart: false,
    costEmissionIntensityRNGBarChart: null,
    isLoadingKeyMetricsSummaryLocalFreight: false,
    keyMetricsSummaryLocalFreight: null,
    isLoadingLaneNameLocalFreight: false,
    laneNameLocalFreight: null,
    rngFreightDate: null
}

export const getTruckFuelTypestPieChart = createAsyncThunk("get/truck/fuel/type/distribution/pie/chart", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getTruckFuelTypesPieChartgApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});


export const getEmissionIntensityRNGBarChart = createAsyncThunk("get/cost/impact/bar/chart/bid/planning", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getEmissionIntensityRNGBarChartApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getKeyMetricsSummaryLocalFreight = createAsyncThunk("get/key/metrics/summary/localFreight", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getKeyMetricsSummaryLocalFreightApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getLaneNameLocalFreight = createAsyncThunk("get/lane/name/localFreight", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getLaneNameLocalFreightApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getRNGFreightDate = createAsyncThunk("get/date/local/freight", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getRNGFreightDateApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const localFreightDataReducer = createSlice({
    name: "local-freight",
    initialState,
    reducers: {
        resetLocalFreight: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTruckFuelTypestPieChart.pending, (state) => {
                state.isLoadingTruckFuelTypesPieChart = true
                state.truckFuelTypesPieChart = null
            })
            .addCase(getTruckFuelTypestPieChart.fulfilled, (state, action) => {
                state.isLoadingTruckFuelTypesPieChart = false
                state.truckFuelTypesPieChart = action.payload
            })
            .addCase(getTruckFuelTypestPieChart.rejected, (state, action) => {
                state.isLoadingTruckFuelTypesPieChart = isCancelRequest(action?.payload)
                state.truckFuelTypesPieChart = null
            })
            .addCase(getEmissionIntensityRNGBarChart.pending, (state) => {
                state.isLoadingEmissionIntensityRNGBarChart = true
                state.costEmissionIntensityRNGBarChart = null
            })
            .addCase(getEmissionIntensityRNGBarChart.fulfilled, (state, action) => {
                state.isLoadingEmissionIntensityRNGBarChart = isCancelRequest(action?.payload)
                state.costEmissionIntensityRNGBarChart = action.payload
            })
            .addCase(getEmissionIntensityRNGBarChart.rejected, (state, action) => {
                state.isLoadingEmissionIntensityRNGBarChart = isCancelRequest(action?.payload)
                state.costEmissionIntensityRNGBarChart = null
            })
            .addCase(getKeyMetricsSummaryLocalFreight.pending, (state) => {
                state.isLoadingKeyMetricsSummaryLocalFreight = true
                state.keyMetricsSummaryLocalFreight = null
            })
            .addCase(getKeyMetricsSummaryLocalFreight.fulfilled, (state, action) => {
                state.isLoadingKeyMetricsSummaryLocalFreight = false
                state.keyMetricsSummaryLocalFreight = action.payload
            })
            .addCase(getKeyMetricsSummaryLocalFreight.rejected, (state, action) => {
                state.isLoadingKeyMetricsSummaryLocalFreight = isCancelRequest(action?.payload)
                state.keyMetricsSummaryLocalFreight = null
            })

            .addCase(getLaneNameLocalFreight.pending, (state) => {
                state.isLoadingLaneNameLocalFreight = true
                state.laneNameLocalFreight = null
            })
            .addCase(getLaneNameLocalFreight.fulfilled, (state, action) => {
                state.isLoadingLaneNameLocalFreight = false
                state.laneNameLocalFreight = action.payload
            })
            .addCase(getLaneNameLocalFreight.rejected, (state, action) => {
                state.isLoadingLaneNameLocalFreight = false
                state.laneNameLocalFreight = null
            })
            .addCase(getRNGFreightDate.pending, (state) => {
                state.rngFreightDate = null
            })
            .addCase(getRNGFreightDate.fulfilled, (state, action) => {
                state.rngFreightDate = action.payload
            })
            .addCase(getRNGFreightDate.rejected, (state, action) => {
                state.rngFreightDate = null
            })
    }
});

// Export actions and reducer
export const { resetLocalFreight } = localFreightDataReducer.actions;
export default localFreightDataReducer.reducer;
