import { Navigate, Outlet, useLocation } from "react-router-dom";
import HeaderLayout from "../component/layouts/header";

import SidebarLayout from "../component/layouts/sidebar";
import { checkRolePermission, getBaseUrl, getLocalStorage } from "utils";
import { Suspense } from "react";
import { BucketFileUpload } from "pages/bucket/fileUpload/BucketFileUpload";
import { useAppSelector, useAppDispatch } from "store/redux.hooks";
import { addUrl, applicationType } from "../store/commonData/commonSlice";
import { logoutPost } from "store/auth/authDataSlice";
// Returns Is user is logged in or not
export const useAuth = () => {
  const userdata: any = getLocalStorage("loginDetails");
  return userdata?.token
    ? { loggedIn: true, userdata }
    : { loggedIn: false, userdata };
};
export const bucketUseAuth = () => {
  const userdata: any = getLocalStorage("bucketLoginDetails");
  return userdata?.token
    ? { loggedIn: true, userdata }
    : { loggedIn: false, userdata };
};
// Checks AuthRouters and redirects them to dashboar
export const AuthRouteCheck = ({ children, userDetails }: any) => {
  const isAuth = useAuth()
  if (!isAuth?.loggedIn) {
    return children;
  }
  else {
    if (userDetails?.data?.login_count === 1) {
      return <Navigate to="/settings" />;
    }
    else {

      return <Navigate to={getBaseUrl(userDetails?.data)} />;
    }
  }
};

export const AuthBucketLoginRouteCheck = ({ children }: any) => {
  const isAuthBucket = bucketUseAuth();
  if (!isAuthBucket?.loggedIn) {
    return children;
  } else {
    return <Navigate to={"/bucket-add"} />;
  }
};

// Checks Routes except AuthRouters and redirects them to respective route or Login page
export const ProtectedRouteCheck = ({ children }: any) => {
  const isAuth = useAuth();
  if (isAuth?.loggedIn) {
    return children;
  } else {
    return <Navigate to={"/"} />;
  }
};
export const ProtectedRouteBucket = () => {
  let isAuthBucket = bucketUseAuth();
  const isAuth = useAuth();
  if (!isAuth) {
    return <Navigate to="/bucket-login" />;
  } else if (checkRolePermission(isAuthBucket?.userdata, "blobUser")) {
    return <BucketFileUpload />;
  } else {
    // Return null or some default component in case no condition is met
    return <Navigate to={"/"} />;
  }
};
const ProtectedRoute = ({ application, isEv, company }: any) => {
  let isAuth = useAuth();
  const { loginDetails } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const regionalLevel = getLocalStorage("regionalLevel")
  const location = useLocation();
  const { pathname } = location;
  const userHasPermission = true
  let companyHasPermission = true
  if (company) {
    companyHasPermission = company.includes(loginDetails?.data?.Company?.slug)
  }
  if (checkRolePermission(loginDetails?.data, "regionalManager") && regionalLevel === "") {
    dispatch(logoutPost())
    return <Navigate to="/" />;
  }
  if (!loginDetails?.data?.token) {
    return <Navigate to="/" />;
  }

  if (!userHasPermission) {
    return <Navigate to="/" />;
  }

  if (!companyHasPermission) {
    return <Navigate to="/" />;
  }

  dispatch(addUrl({ "url": pathname }))
  dispatch(applicationType(application === "admin"))

  if (!checkRolePermission(isAuth?.userdata, "blobUser")) {

    return (
      <section className="insight_top_wrapper">
        <div className="mainDashboard position-relative">
          <SidebarLayout />
          <div className="DashboardWrapper">
            <HeaderLayout />
            <div className="dashboardScreen">
              <Suspense
                fallback={
                  <div className="graph-loader  d-flex justify-content-center align-items-center">
                    <div className="spinner-border spinner-ui d-flex justify-content-center align-items-center" >
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                }
              >
                <div className="container-fluid">
                  <Outlet />
                </div>
              </Suspense>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return null;
  }
};

ProtectedRoute.defaultProps = {
  application: "application"
}
export default ProtectedRoute;
