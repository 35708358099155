import axios from "axios";


// Fetch graph region emission data
const getGraphRegionEmission = async (userData: any, token: any) => {
  try {
    const response = await axios.post(
      "get-region-emission-monthly",
      userData,
      token
    );
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

// Get region emission data
const getRegionEmission = async (
  userData: any,
  token: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post(
      "get-region-emission-reduction",
      userData,
      token
    );
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getConfigConstants = async (userdata:{region_id:string | number},token: { headers: { Authorization: string } }) => {
  try {
    const response = await axios.post("get-config-constants", userdata, token);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};


// Service object that exposes functions
const sustainService = {
  getGraphRegionEmission,
  getRegionEmission,
  getConfigConstants
};

// Export the service object
export default sustainService;
