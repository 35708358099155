import axios from 'axios';


// Method for updating the image user's profile.
const getRoleListApi = async (data: any, token: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post("get-role-data", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const deleteRoleApi = async (data: any, token: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post("delete-role", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const deactivatedRoleApi = async (data: any, token: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post("activate-deactivate-role", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const addRoleApi = async (data: any, token: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post("create-role", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const getRoleDetailByIdApi = async (data: any, token: any) => {
    try {
        // Send a POST request to the "get-single-user detail" endpoint with the provided data and token.
        const response = await axios.post("get-role-details", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}
const updateRoleDetail = async (data: any, token: any) => {
    try {
        // Send a POST request to the "get-single-user detail" endpoint with the provided data and token.
        const response = await axios.post("edit-role", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const getAllModulesApi = async (token: any) => {
    try {
        // Send a POST request to the "get-single-user detail" endpoint with the provided data and token.
        const response = await axios.get("get-all-modules", token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}





const RoleServices = {
    getRoleListApi,
    deleteRoleApi,
    addRoleApi,
    getRoleDetailByIdApi,
    updateRoleDetail,
    getAllModulesApi,
    deactivatedRoleApi
}

// Export the RoleServices class for use in other parts of the application.
export default RoleServices;
