import axios from "axios";

/**
 * Retrieves vendor table data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */

const benchmarkDistance = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("graph-benchmark-distance",userData,userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};
const benchmarkWeight = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("graph-benchmark-weight",userData,userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const benchmarkRegion = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post(
      "map-benchmark-region",
      userData,
      userToken
    );

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getLocation = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("carrier-search",userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getFreightLanes = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("emission-in-lane", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getBenchmark = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("map-benchmark-company", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getBenchmarkRegion = async (userData: any) => {
  try {
    const response = await axios.get(
      "graph-benchmark-region",
      userData
    );

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getBenchmarkCarrierEmissions = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("carrier-emission-table", userData, userToken);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getIndustryStandardEmissions = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("company-emission", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getEmissionIntensityTrend = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("company-emission-graph", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getBenchmarkEmissionsTrendGraph = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("emission-trend-graph", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getIntermodelTrendGraph = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("intermodel-trend-graph", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getEmissionByRegion = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("emission-by-region", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getBenchmarkEmissionsTrendGraphLane = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("emission-trend-graph-lane", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getIntermodelTrendGraphLane = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("intermodel-trend-graph-lane", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const getEmissionByLane = async (
  userData: any,
  userToken: { headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("emission-by-lane", userData, userToken);

    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

// Function to fetch band of weight and distance
const getBands = async (
  userData:any,
  userToken: {headers: { Authorization: string } }
) => {
  try {
    const response = await axios.post("company-band-name",userData, userToken);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const benchmarkService = {
  benchmarkDistance,
  benchmarkWeight,
  benchmarkRegion,
  getLocation,
  getFreightLanes,
  getBenchmark,
  getBenchmarkRegion,
  getBenchmarkCarrierEmissions,
  getIndustryStandardEmissions,
  getEmissionIntensityTrend,
  getBenchmarkEmissionsTrendGraph,
  getIntermodelTrendGraph,
  getEmissionByRegion,
  getEmissionByLane,
  getIntermodelTrendGraphLane,
  getBenchmarkEmissionsTrendGraphLane,
  getBands
};

export default benchmarkService;
