import axios from "axios";

/**
 * Common Services
 */

// Function to fetch emission filter dates

const getFiltersDate = async (token: {
  headers: { Authorization: string };
}): Promise<ApiResponse> => {
  try {
    return (await axios.get("graph-filters-dates", token)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to post region intensity data
const postRegionIntensity = async (
  userData: any,
  token: { headers: { Authorization: string } }
): Promise<ApiResponse> => {
  try {
    return (await axios.post("get-region-intensity-yearly", userData, token)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to fetch regions
const getRegions = async (token: {
  headers: { Authorization: string };
}): Promise<ApiResponse> => {
  try {
    return (await axios.get("get-regions", token)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to post region level glide path data
const postRegionLevelGlidePath = async (userData: any, token: { headers: { Authorization: string } }): Promise<ApiResponse> => {
  try {
    return (await axios.post("get-region-reduction", userData, token)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to fetch project count using API
const getProjectCountApi = async (
  userData: any,
  token: { headers: { Authorization: string } }
): Promise<ApiResponse> => {
  try {
    return (await axios.post("get-project-count", userData, token)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to save site url using API
const saveUrlApi = async (
  payload: any,
  token: { headers: { Authorization: string } }
): Promise<ApiResponse> => {
  try {
    return (await axios.post("save-url", payload, token)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to fetch content using API
const getCmsContentApi = async (
  payload: any,
  token: { headers: { Authorization: string } }
): Promise<ApiResponse> => {
  try {
    return (await axios.post("get-cms-content", payload, token)).data;
  } catch (error: any) {
    throw error;
  }
};

const getNotificationListing = async (
  payload: any,
  token: { headers: { Authorization: string } }
): Promise<ApiResponse> => {
  try {
    return (await axios.get("get-user-notifications", token)).data;
  } catch (error: any) {
    throw error;
  }
};

// Object containing all common services
const commonService = {
  getFiltersDate,
  getProjectCountApi,
  postRegionIntensity,
  getRegions,
  postRegionLevelGlidePath,
  saveUrlApi,
  getCmsContentApi,
  getNotificationListing,
};

// Export the common service object
export default commonService;
